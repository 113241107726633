/** @format */

.App {
	text-align: center;
}

.App-header {
	background-image: url("../../public/bhuvans_clinic.jpg");
	background-size: cover;
	background-position: center;
	color: white;
	padding: 100px 0; /* Increased padding for larger header */
}

.App-header h1 {
	font-size: 3rem;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.gallery-item img {
	width: 100%; /* Make the image take up the full width of the column */
	height: 250px; /* Set a fixed height */
	object-fit: cover; /* Ensures the image covers the entire area without distortion */
	opacity: 0;
	transition: opacity 2s ease-in-out;
}

.gallery-item img.loaded {
	opacity: 1;
}

.social-icons i {
	font-size: 1.5rem;
	margin: 0 0.5rem;
}

footer {
	margin-top: 2rem;
}

footer h5 {
	margin-bottom: 1rem;
}

#about img {
	width: 400px;
	height: 400px;
	object-fit: cover;
	margin-bottom: 20px;
}

#about .countup p {
	margin: 0;
}

/**
 * Hero Section
 *
 * @format
 */

.hero-section {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	text-align: left;
	color: white;
	padding: 20px;
}

.hero-section img {
	width: 30%;
	height: auto;
	opacity: 0.8;
}

.hero-content {
	padding: 20px;
}

.hero-content h1 {
	font-size: 2.5rem;
	font-weight: 700;
}

.hero-content h3 {
	font-size: 1.5rem;
}

.hero-content p {
	font-size: 1.2rem;
	margin: 10px 0 20px;
}

.hero-content .btn-login {
	background-color: #ffd700;
	border: none;
	padding: 10px 20px;
	font-size: 1rem;
	font-weight: 600;
	color: #2c3e50;
}

@media (max-width: 767px) {
	.hero-section {
		flex-direction: column;
		text-align: center;
	}
	.hero-content {
		padding: 10px;
	}
	.hero-content h1 {
		font-size: 1.5rem;
	}
	.hero-content h3 {
		font-size: 1rem;
	}
}

/* Services */
.services-section {
	padding: 50px 0;
	background-color: #f9f9f9;
}

.services-section h2 {
	font-size: 24px;
	color: #333;
}

.service-card {
	border: none;
	height: 15%;
	transition: transform 0.3s ease;
}

.service-card img {
	width: 100%; /* Make the image take up the full width of the card */
	height: auto; /* Maintain the image's aspect ratio */
	max-height: 200px; /* Set a maximum height for the images */
	padding: 10px; /* Add padding around the image */
	background-color: white; /* Set the background color to white */
	object-fit: contain; /* Ensure the entire image fits within the specified height without cropping */
	box-sizing: border-box; /* Include padding in the element's width and height */
}

.service-card:hover {
	transform: translateY(-10px);
}

.service-icon {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 0 auto;
	background-color: #ccc; /* Fallback color */
}

.service-card .card-title {
	font-size: 18px;
	color: #555;
}

.service-card .card-text {
	font-size: 14px;
	color: #777;
}

.view-more-btn {
	padding: 10px 20px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.view-more-btn:hover {
	background-color: #0056b3;
}
